<template>
  <div class="h-100">
    <div v-if="isLoadingPaymentProvider" class="h-100">
      <div class="text-center flex-center h-100">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">{{ loadingTitle }}</span>
        </div>
        <div style="margin-top: 7rem;">
          {{ loadingMessage }}
        </div>
      </div>
    </div>
    <div v-else class="h-100">
      <div class="sidebar-header py-1 px-2">
        <div>
          <h3>Détail du règlement</h3>
        </div>
        <feather-icon icon="XIcon" size="24" @click.stop="closeSidebar" class="close-icon cursor-pointer">
        </feather-icon>
      </div>
      <vs-divider class="m-0" />

      <div class="sidebar-action py-05 px-2">
        <div class="d-flex align-items-center justify-content-between w-100">
          <b-button-group>
              <!-- POSSIBILITE DE MODIFIER UNIQUEMENT LA REMISE EN BANQUE -->
              <b-button class="btn-icon d-flex" variant="primary" @click="editPaymentProvider(paymentProvider.id)" :style="paymentProvider.remainsToBeDeducted > 0 ? 'border-right:1px solid #ccc !important;' : ''">
                <feather-icon icon="EditIcon" /><span style="margin-left:3px">Modifier</span>
              </b-button>
              <!-- POSSIBILITE D'AJOUTER UN DOCUMENT (FACTURE, AVOIR) MAIS SANS TOUCHER AUX ANCIENS REGLEMENT ET SANS MODIFIER LES INFORMATIONS DE REGLEMENT -->
              <b-button v-if="paymentProvider.remainsToBeDeducted > 0" @click="editPaymentProvider(paymentProvider.id)" style="border-left:1px solid #ccc !important; " class="btn-icon d-flex" variant="primary">
                <feather-icon icon="FileMinusIcon" /><span style="margin-left:3px">Solder</span>
              </b-button>
          </b-button-group>
          <div class="d-flex align-items-center justify-end">
            <!-- POSSIBILITE DE SUPPRIMER UN REGLEMENT UNIQUEMENT S'IL N'EST PAS LIE A UN DOCUMENT (FACTURE, AVOIR) -->
            <b-button v-if="!haveDocumentLinked" variant="outline-danger" class="btn-icon"
              @click="deletePaymentProviderLocal(paymentProvider.id)">
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </div>
      </div>

      <vs-divider class="m-0" />

      <div class="sidebar-content">
        <div>
          <h4 class="mb-1 text-primary d-flex justify-content-start">
            <span style="min-width: min-content;">
              <feather-icon icon="ChevronRightIcon" /> Informations
            </span>
          </h4>
          <ul class="listTab">
            <li>
              <span>Type</span>
              <span>{{ paymentProvider.paymentProviderType == 1 ? 'Débit' : 'Crédit' }}</span>
            </li>
            <li>
              <span>Fournisseur</span>
              <span>{{ paymentProvider.companyName }}</span>
            </li>
            <li>
              <span>Date</span>
              <span>{{ formatDate(paymentProvider.date) }}</span>
            </li>
            <li>
              <span>Moyen de paiement</span>
              <span>{{ paymentMethodTranslate(paymentProvider.paymentMethod ? paymentProvider.paymentMethod.id : 0) }}</span>
            </li>
            <li>
              <span>Montant</span>
              <span>{{ formatCurrency(paymentProvider.amount) }}</span>
            </li>
            <li>
              <span>Reste à solder</span>
              <span>{{ formatCurrency(paymentProvider.remainsToBeDeducted) }}</span>
            </li>
            <li v-if="paymentProvider.reference">
              <span>Référence</span>
              <span>{{ paymentProvider.reference }}</span>
            </li>
          </ul>
          <vs-divider v-if="paymentProvider.remittedBank || haveDocumentLinked || paymentProvider.notes"/>
        </div>
        <div
          v-if="paymentProvider.remittedBank">
          <h4 class="mb-1 text-primary">
            <feather-icon icon="DownloadIcon" /> Remise en banque
          </h4>
          <ul class="listTab">
            <li v-if="paymentProvider.remittedBankDate">
              <span>Date</span>
              <span>{{ formatDate(paymentProvider.remittedBankDate) }}</span>
            </li>
            <li v-if="paymentProvider.bankAccountId">
              <span>Compte bancaire</span>
              <span>{{ paymentProvider.bankAccountId }}</span>
            </li>
          </ul>
          <vs-divider v-if="haveDocumentLinked || paymentProvider.notes"/>
        </div>
        <div
          v-if="haveDocumentLinked">
          <h4 class="mb-1 text-primary">
            <feather-icon icon="FileIcon" /> {{ paymentProvider.invoiceProviders.length > 1 || paymentProvider.creditProviders.length > 1 ? 'Documents liés' : 'Document lié' }}
          </h4>
          <div v-if="paymentProvider.invoiceProviders.length > 0">
            <div class="mb-1" v-for="(item, index) in paymentProvider.invoiceProviders">
              <span>{{ natureShortTranslate(item.nature) }} {{ item.documentReference }} - Montant soldé: {{ formatCurrency(item.value) }}</span>
              <feather-icon @click="displayWargingMessageBeforeUnlinkDocument([item.id], item.value, 1)" class="cursor-pointer ml-1" icon="XIcon" size="18" />
            </div>
          </div>
          <div v-if="paymentProvider.creditProviders.length > 0">
            <div clas="mb-1" v-for="(item, index) in paymentProvider.creditProviders">
              <span>{{ natureShortTranslate(item.nature) }} {{ item.documentReference }} - Montant soldé: {{ formatCurrency(item.value) }}</span>
              <feather-icon @click="displayWargingMessageBeforeUnlinkDocument([item.id], item.value, 2)" class="cursor-pointer ml-1" icon="XIcon" size="18" />
            </div>
          </div>
          <vs-divider v-if="paymentProvider.notes"/>
        </div>
        <div
          v-if="paymentProvider.notes">
          <h4 class="mb-1 text-primary">
            <feather-icon icon="FileTextIcon" /> Notes
          </h4>
          <p class="paraphbox">
            <span>{{ paymentProvider.notes }}</span>
          </p>
          <vs-divider />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ENatureShort } from "@/types/api-orisis/enums/enums"
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
import { formatCurrency, formatDate } from "@/types/api-orisis/library/FormatOperations.ts"
import { natureShortTranslate, paymentMethodTranslate } from "@/types/api-orisis/library/TranslateOperations.ts"

export default {
  data() {
    return {
      loadingTitle:"Chargement",
      loadingMessage:"Chargement des détails du paiment...",
    }
  },
  computed: {
    ...mapGetters([
      "isLoadingPaymentProvider",
      "natureList",
      "paymentProvider",
      "paymentMethodsList"
    ]),
    haveDocumentLinked() {
      return (this.paymentProvider.invoiceProviders || this.paymentProvider.creditProviders) && (this.paymentProvider.invoiceProviders.length > 0 || this.paymentProvider.creditProviders.length > 0)
    }
  },
  methods: {
    ...mapActions([ "getPaymentProviderById", "unlinkInvoiceProviderPaymentProvider", "unlinkCreditProviderPaymentProvider", "deletePaymentProvider"]),
    formatCurrency,
    formatDate,
    natureShortTranslate,
    paymentMethodTranslate,
    displayWargingMessageBeforeUnlinkDocument(ids, amount, type) {
      this.$bvModal
        .msgBoxConfirm('Cette action est définitive et irréversible.', {
          title:
            'Êtes-vous sûr de vouloir annuler le règlement de ' + (type == 1 ? 'cette facture fournisseur ?' : 'cet avoir fournisseur ?'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Oui',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value && type == 1) {
            this.unlinkInvoicesProviderPaymentProviderLocal(ids, amount);
          } else if (value && type == 2) {
            this.unlinkCreditsProviderPaymentProviderLocal(ids, amount);
          }
        })
    },
    unlinkInvoicesProviderPaymentProviderLocal(invoiceProviderIds = [], amount) {
      this.unlinkInvoiceProviderPaymentProvider({paymentProvider: this.paymentProvider, invoiceProviderIds: invoiceProviderIds, amount: amount}).then(() => {
        this.$emit("refreshGrid");
      })
    },
    unlinkCreditsProviderPaymentProviderLocal(creditProviderIds = [], amount) {
      this.unlinkCreditProviderPaymentProvider({paymentProvider: this.paymentProvider, creditProviderIds: creditProviderIds, amount: amount}).then(() => {
        this.$emit("refreshGrid");
      })
    },
    async getPaymentProviderDetails(id) {
      if (id != '') {
        this.getPaymentProviderById({
          paymentProviderId: id,
        })
      } else {
        this.$store.commit("SET_PAYMENT_PROVIDER", {})
      }
    },
    async editPaymentProvider(id, type){
      // type == 1 => remise en banque
      // type == 2 => solder le règlement
      this.closeSidebar();
      this.$router.push({ name: 'edit-payment-provider', params: { id: id, documentData: null, title: "Edit règlement ", tips:"Editer un règlement", routeOrigine:'paymentProviders', disabled: true } })
    },
    deletePaymentProviderLocal(id) {
      this.$bvModal
        .msgBoxConfirm('Cette action est définitive et irréversible.', {
          title:
            'Êtes-vous sûr de vouloir supprimer le règlement ?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Supprimer',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.deletePaymentProvider({
              paymentProviderIds: [id],
            })
            this.closeSidebar();
          }
        })
    },
    closeSidebar() {
      this.$emit('closeSidebar');
    },
  },
  mounted(){
    this.loadingTitle = "Chargement",
    this.loadingMessage = "Chargement des détails du règlement..."
  },
  components: {
    VuePerfectScrollbar,
  }
}
</script>

<style lang="scss" scoped>
.sidebar-action {
  flex-direction: column;
}
</style>
